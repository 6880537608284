const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

const lightOrDark = (color) => {
  // Variables for red, green, blue values
  // eslint-disable-next-line
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    // eslint-disable-next-line
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    /* eslint-disable no-bitwise */
    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
    /* eslint-enable no-bitwise */
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return '#000000';
  }
  return '#ffffff';
};

export default function createAvatar(name, avatarHeight, avatarWidth) {
  return {
    sx: {
      height: !avatarHeight ? '40px' : avatarHeight,
      width: !avatarWidth ? '40px' : avatarWidth,
      bgcolor: stringToColor(name),
      color: lightOrDark(stringToColor(name)),
    },
    children: name.charAt(0),
  };
}
