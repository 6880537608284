// Icon
import { Settings, AccountCircle, ContactSupport, Lightbulb } from '@mui/icons-material';

// ----------------------------------------------------------------------

const navSupportConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Idea Board', path: '/ideas', icon:<Lightbulb /> },
      {
        title: 'Support',
        path: '/settings-account',
        icon: <ContactSupport />,
        children: [
          { title: 'Contact Support' , intercom: true },
          // { title: 'Back to previous version',isTokenRequired:true, path: 'https://app.spotlightr.com/#/adminuser/user.token.here' },
          { title: 'Knowledge Base', path: 'https://docs.spotlightr.com' },
          { title: 'Application Status', path: 'https://status.spotlightr.com' },
          { title: 'Dev / API Docs', path: 'https://app.spotlightr.com/docs/api/' },
          { title: 'Release Notes', path: 'https://spotlightr.com/category/release-notes/' },
          // { title: 'Ideas Board', path: '/ideas' },
          // { title: 'Bandwidth Report', openBandwidthPopup: true },
        ],
      },
      { title: 'Settings', path: '/settings', icon: <Settings /> },
      { title: 'Account & Billing', path: '/account', icon: <AccountCircle /> },
    ],
  },
];

export default navSupportConfig;
