// Icons
// import { OndemandVideoOutlined, PeopleOutline, InsertChart } from '@mui/icons-material';
import PeopleOutline from '@mui/icons-material/PeopleOutline';
import InsertChart from '@mui/icons-material/InsertChart';
// import CommentIcon from '@mui/icons-material/Comment';
import Image from '../../../components/Image';

// ----------------------------------------------------------------------

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      // { title: 'Projects', path: '/videos', icon: <OndemandVideoOutlined /> },
      { title: 'Projects', path: '/videos', icon:<Image sx={{width:24, cursor: 'pointer'}} disabledEffect src={`/images/folder.png`} /> },
      {
        title: 'Students',
        path: '/audience',
        icon: <PeopleOutline />,
        children: [
          { title: 'Reports', path: '/audience' },
          { title: 'Comments', path: '/comment' },
        ],
      },
      // { title: 'Student Reports', path: '/audience', icon: <PeopleOutline /> },
      { title: 'Video Reports', path: '/reports', icon: <InsertChart /> },
      // { title: 'Comment Reports', path: '/comment', icon: <CommentIcon /> },
      // { title: 'Quizzes', path: '/quizzes', icon: <QuizOutlined /> },
      // { title: 'Galleries', path: '/galleries', icon: <DvrOutlined /> },
      // { title: 'Playlists', path: '/playlists', icon: <PlaylistPlay /> },
    ],
  },
];

export default sidebarConfig;
