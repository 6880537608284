import PropTypes from 'prop-types';
import { memo } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { NavListRoot } from './NavList';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const { user } = useAuth();
  const ifRestricted = ( list ) => {
    const modulesList = ['Playlists','Quizzes','Galleries']
    const analytics = ['Students','Reports']
    if(analytics.indexOf(list.title) > -1 && user.restricted && !user.teamUser.permissions.analytics) return false 
    if(list.title === 'Support' && user.restricted && !user.teamUser.permissions.support) return false  
    if(modulesList.indexOf(list.title) > -1 && user.restricted && !user.teamUser.permissions.modules) return false  
    return true    
}
  return (
    <Stack direction="row" justifyContent="center" sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}>
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) => (
          <Stack key={group.subheader} direction="row" flexShrink={0}>
            {group.items.map((list) => (
              ifRestricted(list) && <NavListRoot key={list.title} list={list} />
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
