import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

// ----------------------------------------------------------------------

const initialState = {
  createOpen: false,
  setCreateOpen: () => {},
};

const OpenCreateModuleContext = createContext(initialState);

// ----------------------------------------------------------------------

OpenCreateModuleProvider.propTypes = {
  children: PropTypes.node,
};

function OpenCreateModuleProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCollapse = (value) => {
    setIsOpen(value);
  };

  return (
    <OpenCreateModuleContext.Provider
      value={{
        createOpen: isOpen,
        setCreateOpen: handleToggleCollapse,
      }}
    >
      {children}
    </OpenCreateModuleContext.Provider>
  );
}

export { OpenCreateModuleProvider, OpenCreateModuleContext };
