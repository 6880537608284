const setSession = (accessToken, teamUser='') => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('teamUser', teamUser);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('teamUser');
    // delete axios.defaults.headers.common.Authorization;
  }
};

export { setSession };
