import PropTypes from 'prop-types';

import { ListItemText,Box,IconButton,Tooltip,TextField,Popover, CircularProgress, List, ListItem } from '@mui/material';

import Iconify from 'src/components/Iconify'; 

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker';
import { areEqual, getPastDateFromNow } from 'src/utils/formatTime';
import moment from "moment"

DateFilter.propTypes={
    handleChangeAdditionalOptions:PropTypes.func,
    handleOpenPopover:PropTypes.func,
    handleClosePopover:PropTypes.func,
    anchorElAdditionalOptions:PropTypes.any,
    isOpenOption:PropTypes.bool,
    dateRangeValue:PropTypes.any,
    handleDateRangeValue:PropTypes.func,
    triggerOk:PropTypes.bool,
    analyticsDataLoading:PropTypes.bool,
    isAnalytics:PropTypes.bool
}
export default function DateFilter({
    handleChangeAdditionalOptions,
    handleOpenPopover,
    handleClosePopover,
    anchorElAdditionalOptions,
    isOpenOption,
    dateRangeValue,
    handleDateRangeValue,
    triggerOk,
    analyticsDataLoading,
    isAnalytics
}){
    return(<>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDateRangePicker 
                    startText="Start"
                    maxDate={new Date(moment(new Date()).add(1,'d'))}
                    minDate={getPastDateFromNow(366)}
                    disableHighlightToday
                    // disableFuture
                value={dateRangeValue}
                onAccept={(newValue)=>{
                    if(triggerOk){
                        const start = newValue[0]
                        const end = newValue[1]
                        handleDateRangeValue(newValue, areEqual(start,end))}
                    }
                }
                onChange={(newValue) => {
                    if(!triggerOk){
                        const [start, end] = newValue
                        handleDateRangeValue(newValue, areEqual(start,end))}
                    }
                }
                renderInput={(startProps, endProps) => (
                    <>
                    <TextField sx={{pr: 1}} size="small" { ...startProps} onKeyPress={(e)=>{e.preventDefault()}} onKeyDown={(e)=>{e.preventDefault()}} onKeyUp={(e)=>{e.preventDefault()}}/>
                    {/* <Box sx={{ mx: 2 }}> to </Box> */}
                    <TextField size="small" { ...endProps} onKeyPress={(e)=>{e.preventDefault()}} onKeyDown={(e)=>{e.preventDefault()}} onKeyUp={(e)=>{e.preventDefault()}}/>
                    {!analyticsDataLoading && <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Tooltip title="Additional Options">
                            <IconButton id={isAnalytics ? "btnAdditionalOptions2" : "btnAdditionalOptions"} onClick={(e) => {handleOpenPopover(e,!isOpenOption)}}>
                                <Iconify icon={'zmdi:more-vert'} width={18} height={18} />
                            </IconButton>
                        </Tooltip>
                        <Popover open={isOpenOption}  anchorEl={anchorElAdditionalOptions} onClose={handleClosePopover} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}>
                            <List dense sx={{cursor: 'pointer'}} >
                                <ListItem onClick={() => handleChangeAdditionalOptions(3)}>
                                    <ListItemText>Last 3 Days</ListItemText>
                                </ListItem>
                                <ListItem onClick={() => handleChangeAdditionalOptions(7)}>
                                    <ListItemText>Last 7 Days</ListItemText>
                                </ListItem>
                                <ListItem onClick={() => handleChangeAdditionalOptions(30)}>
                                    <ListItemText>Last 30 Days</ListItemText>
                                </ListItem>
                                <ListItem onClick={() => handleChangeAdditionalOptions('','monthToDate')}>
                                    <ListItemText>Month to Date</ListItemText>
                                </ListItem>
                                <ListItem onClick={() => handleChangeAdditionalOptions('','previousMonth')}>
                                    <ListItemText>{moment(new Date()).subtract(1, "month").format('MMMM')}</ListItemText>
                                </ListItem>
                                <ListItem onClick={() => handleChangeAdditionalOptions('','prePreviousMonth')}>
                                    <ListItemText>{moment(new Date()).subtract(2, "month").format('MMMM')}</ListItemText>
                                </ListItem>
                                <ListItem onClick={() => handleChangeAdditionalOptions('', 'yearToDate')}>
                                    <ListItemText>Year to Date</ListItemText>
                                </ListItem>
                            </List>
                        </Popover>
                        
                    </Box>}
                    {analyticsDataLoading && <Box><CircularProgress color="inherit" size="1.3rem" sx={{ml:1}}/></Box>}
                    </>
                )}
                />
            </LocalizationProvider>
        </>)
}