import { format, getTime, formatDistanceToNow, isEqual, isBefore, isAfter, differenceInMilliseconds } from 'date-fns';
import * as moment from 'moment'

// ----------------------------------------------------------------------

export function fDate(date, timeFormat) {
  return format(new Date(date), timeFormat || 'dd MMMM yyyy');
}


export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function getYesterdayDate() { 
  return new Date(moment().subtract(1, 'days'));
}
export function getPastDateFromNow( days ) { 
  return new Date(moment().subtract(days, 'days'));
}

export function getTomorrowFromDate(date) { 
  return new Date(moment(date).add(1, 'days'));
}

export function areEqual(dateLeft,dateRight) {
  return isEqual(new Date(dateRight), new Date(dateRight))
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
export function getTimeFromNow(date , format) { 
  let time = differenceInMilliseconds(new Date(), date)
  
  if (typeof format === 'string') {
    const lowerCaseFormat = format.toLowerCase();
    if(lowerCaseFormat === 'seconds' || lowerCaseFormat === 's') time /= 1000 
    if(lowerCaseFormat === 'hours' || lowerCaseFormat === 'h') time /= (1000 * 60 * 60)
    if(lowerCaseFormat === 'days' || lowerCaseFormat === 'd') time /= (1000 * 60 * 60 * 24)
  } 
  
  return time
}

export function fVideoDuration(time) {
  let result = '';
  let seconds = time;

  const days = Math.floor(seconds / (24 * 3600));
  seconds -= days * 24 * 3600;

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;


  if (days > 0) result = `${days > 9 ? days : `0${days}`}:`;
  if (hours > 0) result += `${hours > 9 ? hours : `0${hours}`}:`;
  result += `${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`}`;

  return result;
}

export function momentfToNow(date){
  return moment.utc(date).local().startOf('seconds').fromNow()
}
export function isTheFirstDateBefore(firstDate,secondDate){
  return  isBefore(new Date(firstDate), new Date(secondDate))
}
export function isTheFirstDateAfter(firstDate,secondDate){
  return  isAfter(new Date(firstDate), new Date(secondDate))
}
export function momentWeekToNow(date){
  return moment.utc().local().diff(date, 'weeks')
}