import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

// ----------------------------------------------------------------------

const initialState = {
  isPublishOpen: false,
  onTogglePublish: () => {},
};

const PublishPopupContext = createContext(initialState);

// ----------------------------------------------------------------------

PublishPopupProvider.propTypes = {
  children: PropTypes.node,
};

function PublishPopupProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <PublishPopupContext.Provider
      value={{
        isPublishOpen: isOpen,
        onTogglePublish: handleToggleCollapse,
      }}
    >
      {children}
    </PublishPopupContext.Provider>
  );
}

export { PublishPopupProvider, PublishPopupContext };
