import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  addedCard: null,
  customerCards: null,
  setupIntent: null,
};

const slice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CLIENT_CARD
    getClientCardsSuccess(state, action) {
      state.isLoading = false;
      state.customerCards = action.payload;
    },

    addPaymentMethodSuccess(state, action) {
      state.isLoading = false;
      state.addedCard = action.payload;
    },

    createSetupIntentSuccess(state, action) {
      state.isLoading = false;
      state.setupIntent = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getClientCards(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/stripe/payments?userID=${user.id}&Token=${user.token}`);
      dispatch(slice.actions.getPlaylistsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function addPaymentMethodEndpoint(stripeToken, user,isDefault) {
  const data = `token=${stripeToken.id}&processorID=${user.processorID}&default=${isDefault}`;
  const response = await axios.post('/user/stripe/addPaymentMethod', data);
  return response
}


export function addPaymentMethod(stripeToken, user,isDefault) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await addPaymentMethodEndpoint(stripeToken, user,isDefault);
      dispatch(slice.actions.addPaymentMethodSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createSetupIntent(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/fix/createSetupIntent?customer=${user.processorID}`);
      dispatch(slice.actions.createSetupIntentSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export async function setDefaultPaymentMethod(cardId, user) {
//   const data = `token=${user.token}&cardId=${cardId}&processorID=${user.processorID}&default=true`;
//   const response = await axios.post('/user/stripe/setDefaultPaymentMethod', data);
//   return response
// }
