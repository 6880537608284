import CryptoJS from 'crypto-js';
import { useState, useEffect, useCallback } from 'react';

// @mui
import { Box, CircularProgress, Stack } from '@mui/material';

import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

export default function IdeasBoard() {
    const [currentProjectUrl, setCurrentProjectUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const { user } = useAuth();

    

    const generateToken =useCallback(
        () => {
            // Defining our token parts
            const header = {
                "alg": "HS256",
                "typ": "JWT"
            };
            const data = {
                "id": user.id,
                "name": `${user.firstName  } ${  user.lastName}`,
                "email": user.username
            };
            const secret = "e7a38e43-d4b0-4739-af8c-e9e53a878a60";
            function base64url(source) {
                let encodedSource = CryptoJS.enc.Base64.stringify(source);
                encodedSource = encodedSource.replace(/=+$/, '');
                encodedSource = encodedSource.replace(/\+/g, '-');
                encodedSource = encodedSource.replace(/\//g, '_');
                return encodedSource;
            }
            const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
            const encodedHeader = base64url(stringifiedHeader);
            const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
            const encodedData = base64url(stringifiedData);
            let signature = `${encodedHeader  }.${  encodedData}`;
            signature = CryptoJS.HmacSHA256(signature, secret);
            signature = base64url(signature);
            const URL = `https://portal.productboard.com/jhvdzrgu5izg9wep7tjokwdd?token=${encodedHeader}.${encodedData}.${signature}`
            setCurrentProjectUrl(URL)            
        },      
      [user.firstName, user.id, user.lastName, user.username]
    )

    useEffect(() => {
    if(!(user?.restricted && !user?.teamUser?.permissions?.support)) {
        setLoading(true)
        generateToken()
    }
    }, [generateToken, user?.restricted, user?.teamUser?.permissions?.support])
    
    if(user && user?.teamUser && user?.restricted && !user?.teamUser?.permissions?.suppert) return null
    
    return (
        <Box sx={{ p: 0, height: '90vh'}}> 
            {loading && <Stack alignItems="center" justifyContent="center" sx={{width: '100%', height: '100%'}}>
                <CircularProgress sx={{color: '#fea02f'}} size='70px' /> 
            </Stack>}     
            {currentProjectUrl && <iframe onLoad={()=>setLoading(false)} style={{border: 'none'}} src={currentProjectUrl} height="100%" width="100%" title="affiliate0iframe"/>}
        </Box>     
    );
}
