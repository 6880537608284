import React, { useState, useEffect } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

// @mui
import { styled } from '@mui/material/styles';
import { TextField, CircularProgress, Slide } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// hooks
import { useNavigate } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';

// utils
import cssStyles from '../../../utils/cssStyles';
import axios from '../../../utils/axios';

// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

// ----------------------------------------------------------------------

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => option.name,
});

export default function Searchbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = isOpen && options.length === 0;

  const { user } = useAuth();  
  const navigate = useNavigate();
  const { isCollapseSearchBar, onToggleCollapseSearchBar } = useCollapseDrawer();

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const data = `Token=${user.token}`;
      const res = await axios.post('/search/global', data);
      if (active) {
        setOptions(res.data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, user]);

  useEffect(() => {
    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen]);
  const searchNavigate = ( option ) => {
    if(option?.type && option?.id) navigate(`/${option.type === 'projects' ? 'videos' : option.type}/${btoa(option.id)}`)
    setTimeout(() => {
      onToggleCollapseSearchBar()
    }, 500); 
  }
  return (
    <Slide direction="down" in={isCollapseSearchBar} mountOnEnter unmountOnExit>
      <SearchbarStyle>
        <Autocomplete
          fullWidth
          open={isOpen}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          getOptionLabel={(option) => `${option.name}`}
          groupBy={(option) => option.type}
          options={options}
          loading={loading}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search..."
              variant="outlined"
              autoFocus
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20, mr: 1 }} />
                ),
                endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
              }}
            />
          )}
          onChange={(e,option)=> searchNavigate(option)}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(option.name, inputValue);
            const parts = parse(option.name, matches);
            const newKey = option.id;
            const newProps = { ...props, key: newKey };
            return (
              <li {...newProps}>
                <div>
                  {parts.map((part, index) => (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}                    
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </SearchbarStyle>
    </Slide>
  );
}
