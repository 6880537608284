import { forwardRef } from "react";
import { Slide } from "@mui/material";

export const SlideInRight = forwardRef((props, ref) => <Slide
    direction="left"
    ref={ref}
    {...props}
    // overwrite init props
    timeout={{enter:333,exit:250}}        
/>);
