import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  playlists: [],
  currentPlaylist: null,
  createdPlaylistId: null,
};

const slice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CREATED ID
    getCreatedPlaylistId(state, action) {
      state.isLoading = false;
      state.createdPlaylistId = action.payload;
      // console.log(state.createdPlaylistId, 'PUT-PLAYLIST-ID-IN-STATE') 
    },

    // GET PLAYLISTS
    getPlaylistsSuccess(state, action) {
      state.isLoading = false;
      state.playlists = action.payload;
    },

    // GET CURRENT_PLAYLIST
    getCurrentPlaylistSuccess(state, action) {
      state.isLoading = false;
      const playlist = action.payload;
      const data = JSON.parse(playlist.data);

      state.currentPlaylist = { ...playlist, data };
    },
    updateCurrentPlaylistSuccess(state, action) {
      state.isLoading = false;
      state.currentPlaylist = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getPlaylists(token,groups = '') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/playlist?Token=${token}&videoGroup=${groups}`);
      dispatch(slice.actions.getPlaylistsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlaylist(token, playlistId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/playlist?id=${playlistId}&Token=${token}`);
      dispatch(slice.actions.getCurrentPlaylistSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updatePlaylist(playlist, user,groups = null) {
  // const preparedPlaylistData = JSON.stringify(playlist.data).replace( /&/g, "%26");
  // const data= `Token=${  user.token }&id=${  playlist.id  }&name=${  encodeURIComponent(playlist.name)  }&data=${  preparedPlaylistData}`
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(playlist)
      await updatePlaylistEndPoint(playlist, user,groups)
      dispatch(getPlaylist(user.token, playlist.id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
/* eslint-disable consistent-return */
export function createPlaylist(token, name, returnValue,groupId = null) {
  const playlistData = {
    'position': 'right',
    'skin': 'default',
    'allowSkipping': true,
    'themeData': {'id': 0}
  };
  let data = `Token=${token}&name=${encodeURIComponent(name)}&data=${JSON.stringify(playlistData).replace( /&/g, "%26")}`;
  if(groupId){
    data = `${data}&videoGroup=${groupId}`
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/playlist`, data);
      dispatch(slice.actions.getCreatedPlaylistId(response.data));
      if( returnValue ) return response.data
      // console.log(response.data, 'RESPONSE-DATA-PLAYLIST-ID')  
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
} // eslint-disable-line react-hooks/exhaustive-deps
export async function deletePlaylist(user, selectedPlaylists){
  const data = `token=${user.token}&flag=playlist&id=${selectedPlaylists}`;
  const response =  await axios.post('/deleteMachine',data);
  return response
}
export async function clonePlaylists(user,id,type) {
  const response =  await axios.post(`/clone?Token=${user.token}&id=${id}&table=${type}`);
  return response
};
export async function updatePlaylistEndPoint(playlist,user,groups){
  const preparedPlaylistData = playlist.data ? `&data=${JSON.stringify(playlist.data).replace( /&/g, "%26")}` : '';
  let data= `Token=${  user.token }&id=${  playlist.id  }&name=${  encodeURIComponent(playlist.name)  }${preparedPlaylistData}&tags=${typeof playlist.tags !== "string" ? JSON.stringify(playlist.tags) : playlist.tags}`
  if(groups)
    data = `${data}&videoGroup=${groups}`
  const response = await axios.put(`/playlist` , data);
  return response;
}