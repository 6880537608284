
import * as React from 'react';
import PropTypes from 'prop-types';

import { Close as IconClose } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';


SnackbarCloseButton.propTypes={
  snackbarKey:PropTypes.any
}
function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose />
    </IconButton>
  );
}

export default SnackbarCloseButton;