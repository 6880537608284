import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '@mui/icons-material/Google';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { trackEventActionsFunction } from 'src/utils/common';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';

import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';


// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login, googleLoginOrRegister } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const [googleSigninLoader,setShowGoogleSignInLoader] = useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    
    try {
      trackEventActionsFunction('Registration', 'User Login', 'Login Click')
      await login(data.email, data.password);
      trackEventActionsFunction('Registration', 'User Login', 'Login Success')
    } catch (error) {
      trackEventActionsFunction('Registration', 'User Login', 'Login Fail')
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', {
          type: 'manual',
          message: error,
        });
      }
    }
  };
  const googleUserLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      googleLoginOrRegister(codeResponse.access_token).then((res)=>{
        // setShowGoogleSignInLoader(false)
        if(res && res.data){
          const emailAddress = res.data.email
          try{
            login(emailAddress, btoa(emailAddress));
          }
          catch(error){
            console.log(error)
            reset();
            if (isMountedRef.current) {
              setError('afterSubmit', {
                type: 'manual',
                message: error,
              });
            }
          }
        }
      }).catch((err) => {
        console.log(err)
        setShowGoogleSignInLoader(false)
      })
    },
    onError: (error) => {
      setShowGoogleSignInLoader(false)
      console.log('Login Failed:', error)
    }, 
    onNonOAuthError: (error) =>{
      setShowGoogleSignInLoader(false)
      console.log('Login Failed before authenticaion:', error)
    } 
  });
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />


      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <RHFCheckbox name="remember" label="Remember me" />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton disabled={googleSigninLoader} fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Login
      </LoadingButton>
      <Divider>OR</Divider>
      <LoadingButton onClick={(e)=>{setShowGoogleSignInLoader(true);googleUserLogin(e)}} fullWidth size="large" type="submit" variant="outlined" loading={googleSigninLoader}>
        <GoogleIcon sx={{marginRight:'10px'}}/> Sign in with Google
      </LoadingButton>
      {/* <GoogleLogin
        onSuccess={credentialResponse => {
          googleLoginOrRegister(credentialResponse.credential).then((res)=>{
            if(res && res.data){
              const emailAddress = res.data.email
              try{
                login(emailAddress, btoa(emailAddress));
              }
              catch(error){
                console.log(error)
                reset();
                if (isMountedRef.current) {
                  setError('afterSubmit', {
                    type: 'manual',
                    message: error,
                  });
                }
              }
            }
          }).catch((err) => {
            console.log(err)
          })
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      /> */}
      </Stack>
    </FormProvider>
  );
}
