import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, IconButton, Tooltip } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import * as moment from 'moment';
import CircularProgressWithLabel from 'src/components/shared/CircularProgressWithLabel';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarTrialExpire.propTypes = {
  isCollapse: PropTypes.bool,
  user: PropTypes.object
};

export const calculateRemainingTrialDays = (trialExpire) => {
    let days = 0
    const trialExpireDateValue = new Date(trialExpire);
    const currentDate = Date.now();
    let message = '';
    if(trialExpireDateValue < currentDate){
      days = moment(currentDate).startOf('day').diff(moment(trialExpireDateValue).startOf('day'), 'days');
      message = ` trial has expired `
    }
    else{
        days = moment((trialExpire)).startOf('day').diff(moment(currentDate).startOf('day'), 'days');
        if(days > 0)
          message = days <=3 ? ` trial expiring soon` : ` days left on trial`
    }

    return {
        message,
        days
    }
}

export default function NavbarTrialExpire({ isCollapse, user }) {
  const navigate = useNavigate()
  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <CircularProgressWithLabel total={14} totalPercentage={100} numbers={calculateRemainingTrialDays(user.trialExpire).days} />
        {  !isCollapse &&
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', alignItems:'center',gap:.5}}>
              <Typography variant="subtitle2">
              {(calculateRemainingTrialDays(user.trialExpire)).message}
              </Typography>
              <Tooltip title="Manage Subscription">
                <IconButton aria-label="outlined" component="span" sx={{p:.75}} onClick={()=>{navigate('/account')}}>
                  <CardMembershipIcon/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        }
      </RootStyle>
    </Link>
  );
}
