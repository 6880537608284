/* eslint-disable react/no-danger */
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// mui
import { CircularProgress, Stack, Dialog, DialogTitle, DialogContent, Typography, TextField, IconButton, Divider } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

// hooks
import { useNavigate } from 'react-router';
import { momentfToNow } from 'src/utils/formatTime';

import useAuth from '../../hooks/useAuth';

// components
import Iconify from '../Iconify';
// import PlayerThemeEditor from 'src/sections/@dashboard/settings/Theme/PlayerThemeEditor';
// import SelectTheme from './SelectTheme';
// import CustomTitleDialog from './shared/CustomTitleDialog';

// utils
import axios from '../../utils/axios';
import { SlideInRight } from '../../layouts/transitions';
import Image from '../Image';


CustomSearchDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    // displayLoading: PropTypes.bool
};

const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => option.name,
});

export default function CustomSearchDialog({
    open,
    handleClose,
    // displayLoading
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = isOpen && options.length === 0;
    const { user } = useAuth();
    const navigate = useNavigate();

    const groupByTypeAndSortByDate = (array) => array.reduce((result, currentValue) => {
            (result[currentValue.type] = result[currentValue.type] || []).push(currentValue);
            result[currentValue.type].sort((a, b) => b.updated.localeCompare(a.updated))
            return result;
    }, {});


    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            const data = `Token=${user.token}`;
            const res = await axios.post('/search/global', data);
            if (active) {
                let sortedOptionsData = [...options]
                const optionsGroupedByTypes = groupByTypeAndSortByDate(res.data);
                console.log(optionsGroupedByTypes)
                Object.keys(optionsGroupedByTypes).forEach((key)=>{
                    // optionsGroupedByTypes[key].forEach((x)=>{
                    //     x.name = `${x.name} (${momentfToNow(x.updated)})`
                    // })
                    sortedOptionsData = sortedOptionsData.concat(optionsGroupedByTypes[key])
                })
                setOptions(sortedOptionsData);
            }
        })();

        return () => {
            active = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, user]);

    useEffect(()=>{
        setIsOpen(false);
        setOptions([]);
    },[])

    useEffect(() => {
        if (!isOpen) {
            setOptions([]);
        }
        if (isOpen && !open) {
            setIsOpen(false);
        }
    }, [isOpen, open]);

    const searchNavigate = (option) => {
        if (option?.type && option?.id) navigate(`/${option.type}/${btoa(option.id)}`)
        // setTimeout(() => {
            // onToggleCollapseSearchBar()
            handleClose()
        // }, 500);
    }

    return (
        <>
            <Dialog TransitionComponent={SlideInRight} fullWidth maxWidth="md" open={open} onClose={handleClose}>
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 3,
                        py: 1.5,
                    }}>
                    <Typography sx={{
                        fontSize: '1.125 rem',
                        fontWeight: 700
                    }}>Search</Typography>
                    <IconButton onClick={() => { handleClose() }}>
                        <HighlightOffIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent sx={{ mt: 0 }}>
                    <Stack sx={{ py: 1 }} spacing={1}>
                        {/* <TextField label="Search..." variant="outlined"/> */}
                        <Autocomplete
                            fullWidth
                            open={isOpen}
                            onOpen={() => {
                                setIsOpen(true);
                            }}
                            // onClose={() => {
                            //     setIsOpen(false);
                            // }}
                            getOptionLabel={(option) => `${option.name}`}
                            groupBy={(option) => option.type}
                            renderGroup={(params) => (
                                <li>
                                    <Typography sx={{textTransform: 'capitalize', pl: .5, pt: 3.5, fontWeight: 500}}>{params.children.length} {params.group}</Typography>
                                    <Stack>{params.children}</Stack>
                                </li>
                            )}
                            options={options}
                            loading={loading}
                            filterOptions={filterOptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search..."
                                    variant="outlined"
                                    autoFocus
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20, mr: 1 }} />
                                        ),
                                        endAdornment: <>{loading ? <CircularProgress color="inherit" size={20} /> : null}</>,
                                    }}
                                />
                            )}
                            onChange={(e, option) => searchNavigate(option)}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.name, inputValue);
                                const parts = parse(option.name, matches);
                                const newKey = option.id;
                                const newProps = { ...props, key: newKey, style: { padding:0 , margin: '5px 0 0' } };
                                return (
                                    <li {...newProps}>
                                        <Stack direction="row" alignItems="center" sx={{width: '100%'}} justifyContent="space-between">
                                            <Stack direction="row" sx={{maxWidth:'66%', p: option.type !== 'video' ? 1 : 0 }} gap={1} >
                                               {option.type === 'video' && <Image
                                                    disabledEffect
                                                    alt={option.name}
                                                    src={`https://images.spotlightr.com/video/image?id=${option.id}&type=image&optimizer=image&width=200`}
                                                    sx={{ borderRadius: 0.25, width: 66, height: 40, minWidth: 66, minHeight: 40 }}
                                                />}
                                                <Typography sx={{ display: 'flex', alignItems: 'center', maxWidth: '100%'}} noWrap>
                                                {parts.map((part, index) => (
                                                        // eslint-disable-next-line jsx-a11y/no-static-element-interactions                                                        
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontWeight: part.highlight ? 700 : 400,
                                                                width: parts.length-1 === index ? '100%' : '',
                                                                overflow: parts.length-1 === index ? 'hidden' : '',
                                                                textOverflow: parts.length-1 === index ? 'ellipsis' : '',
                                                            }}
                                                        >
                                                            <span 
                                                                dangerouslySetInnerHTML = {{
                                                                    __html:part.text.replace(/ /g, '<span>&nbsp;</span>')
                                                                }}
                                                            />
                                                        </span>
                                                    ))}
                                                </Typography>
                                            </Stack>

                                            <Typography component="span" sx={{fontSize: '12px',float:'right', p:1}}>&nbsp;({momentfToNow(option.updated)})
                                            </Typography>
                                        </Stack>
                                    </li>
                                );
                            }}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}
