// ----------------------------------------------------------------------

export default function IconButton(theme) {
    return {
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&[aria-label~="confirm-check-icon"]': {
                background: theme.palette.warning.main,
                color: theme.palette.warning.contrastText,
              },
              '&[aria-label~="primary-check-icon"]': {
                boxShadow: theme.customShadows.z8,                     
                backgroundColor: theme.palette.primary.main,
                mr:theme.spacing(1),
                "&:hover": {
                    backgroundColor:theme.palette.primary.dark,
                },
                '& .MuiSvgIcon-root': {
                    color: "#fff",
                    opacity: 1
                },
              },
              '&[aria-label~="outlined"]': {
                boxShadow: theme.customShadows.z8,  
                border: '1px solid',
                borderColor:  '#00897b',
                mr:theme.spacing(1),
                "&:hover": {
                    backgroundColor:'#ebf6f5',
                },
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.main,
                    opacity: 1
                },
              }
          },
        },
      },
    };
  }