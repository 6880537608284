import React, { useEffect, useState } from 'react'

import { useSnackbar } from 'notistack';

// @mui
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import { SlideInRight } from 'src/layouts/transitions';

// @Hooks
import useAuth from '../hooks/useAuth';

// @Utils
import { fDate, getTimeFromNow, getYesterdayDate } from '../utils/formatTime';
import axios from '../utils/axios';
import { checkExists } from '../utils/common';


  
export default function SwitchToYearlyDialog() {
  const {user, updateUser,getUserPlans} = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false)
  
  const [plan, setPlan] = useState({});
  const globalSettings = user?.globalSettings !== '' && user?.globalSettings !== undefined ? { ...JSON.parse(user.globalSettings) }   :  {};
  
  useEffect(() => {    
    const subscription = user.stripeSubscriptions?.data[0]?.items?.data[0]
    const currentPeriodEnd = user.stripeSubscriptions?.data[0]?.current_period_end
    const startDate = user.stripeSubscriptions?.data[0]?.start_date
    
    // check if it is monthly   
    // check if created 45 days ago
    // check if never denied or if last denie was 45 days ago
    if(
        subscription?.price?.nickname?.indexOf('Monthly') > -1 &&
        !checkExists(['SPARK', 'AURORA', 'POLARIS', 'SUPERNOVA', 'ENTERPRISE', 'STARTUP'], user.license) &&  
        checkIfCreatedOrDenied(subscription?.created) &&
        checkIfCreatedOrDenied(startDate) &&
        !user.lastInvoice &&
        (!globalSettings.yearlySwitchShown || checkIfCreatedOrDenied(globalSettings.yearlySwitchShown))
      )
      getPlansData(currentPeriodEnd)
    
    return () => {
      setOpen(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  const getPlansData = async (dueTo) => {
    try {
      const res = await getUserPlans(user)
      if(res?.data?.length > 0) {
        setPlan({ ...res.data.find(plan => plan.name === user.license) || {}, dueTo })
        setOpen(true)
      }      
    } catch (err) {
      console.log(err)
    }
  }

  const checkIfCreatedOrDenied = (date) => date && getTimeFromNow(new Date(date), 'days') > 60

  const updateUserGlobalSettings = async () => {    
    // globalSettings.yearlySwitchShown = new Date()
    globalSettings.yearlySwitchShown = getYesterdayDate()
    const userData = { ...user, globalSettings: JSON.stringify(globalSettings) }

    setOpen(false)
    await updateUser(userData,userData)
  }

  const handlConfirmSubscription = () => {
    setOpen(false)
    updateUserGlobalSettings()
    try {      
      const response = axios.get(`/user/switchYearly?userID=${user.id}`)         
      if(response.status === 200) enqueueSnackbar("You will be switched to Yearly Billing")
    } catch (error) {
      enqueueSnackbar('Something went wrong')
    }    
  }

  return (
        <Dialog TransitionComponent={SlideInRight} fullWidth maxWidth="sm" open={open} onClose={updateUserGlobalSettings}>
          <DialogContent sx={{position: 'relative'}} >         
            <Stack  
              sx={{
                position: 'absolute',
                width: '33%',
                top:0,
                bottom:0,
                right:0,
                backgroundImage: "url(/images/switch-to-yearly.png)",
                backgroundSize: 'cover'
              }}
            />
            <Stack pr="calc(33% + 16px)">
              <Typography  variant="h5">Save {(((plan.monthly*12 - plan.yearly)/(plan.monthly*12))*100).toFixed()}% by switching to Yearly Billing</Typography>
              <Stack p={1} mt={4} sx={{ position: 'relative', border: '2px solid teal', borderRadius: 1}}>
                <Typography>{plan.name} Yearly</Typography>
                <Typography variant="subtitle2">${plan.yearly} ( ${(plan.yearly/12).toFixed()}/month )</Typography>
                <Chip 
                  size='small' 
                  color="warning"
                  sx={{position: 'absolute', right:5, top:15}} 
                  label="BEST VALUE"  
                />
              </Stack>
              <Stack my={1}   >
                <Typography variant="caption" >
                <b>Current payments </b>
                ${plan.monthly}/month  
                (${plan.monthly*12}/year)</Typography>
              </Stack>
              
              <Stack mt={2} p={1} mb={1} direction="row" alignItems='center' justifyContent="space-between" sx={{border: "1px solid #cecece", borderRadius: 1}}>
                <Typography>Due on {plan.dueTo ? fDate(plan.dueTo*1000, 'd MMMM') : 'NAN'}</Typography>
                <Typography>${plan.yearly}</Typography>
              </Stack>    
              <Button size="medium" variant="contained" fullWidth onClick={handlConfirmSubscription}>Switch to Yearly Billing</Button>        
              <Button sx={{mt:.5, width: 'max-content', alignSelf: 'center'}} size="medium" variant="text" onClick={updateUserGlobalSettings}>Not at this time</Button>        
            </Stack>           
          </DialogContent>  
        </Dialog>)
}
