import {useState} from 'react'

import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, IconButton, Tooltip } from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CircularProgressWithLabel from 'src/components/shared/CircularProgressWithLabel';
import UserConsumptionDialoge from 'src/sections/@dashboard/accountBilling/shared/UserConsumptionDialoge';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

NavbarTrialExpireBandwidth.propTypes = {
  isCollapse: PropTypes.bool,
  user: PropTypes.object
};

export default function NavbarTrialExpireBandwidth({ isCollapse, user }) {

  const [openDialoge,setOpenDialoge] = useState(false)
  const [usageType,setUsageType] = useState('')

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <CircularProgressWithLabel total={Number(user.bandwidthAvailable)} totalPercentage={100} numbers={Number(user.bandwidthUsed/1024).toFixed()} displayPercentageValue/>
        {  !isCollapse &&
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap', alignItems: 'center', gap:.5 }}>
              <Typography variant="caption">
                ({(parseFloat(user.bandwidthUsed/1024).toFixed(1))} GB / {user.bandwidthAvailable / 1} GB) <br/>
                {user.bandwidthOverageSize && `${(parseFloat(user.bandwidthOverageSize/1024).toFixed(1))} GB Overage`}
              </Typography>
              <Tooltip title="Review Bandwidth Usage">              
                <IconButton  
                  aria-label="outlined"  
                  component="span" 
                  sx={{p:.75}}
                  onClick={()=>{setOpenDialoge(!openDialoge);setUsageType('bandwidth')}}>
                  
                    <ShowChartIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        }
      </RootStyle>
      {openDialoge && usageType !== '' && <UserConsumptionDialoge open={openDialoge && usageType!==''} usageType={usageType} user={user} handleClose={()=>{setOpenDialoge(!openDialoge);setUsageType('')}}/> }
    </Link>
  );

}
