import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  translations: [],
  playerThemes: [],
  affiliateSrc: '',
  userFiles: [],
  userIntegrations: [],
  teamUsers: [],
  suppIntegrations:[],
  usedStorage:0,
  openAddIntegration: false
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Translations
    getTranslationsSuccess(state, action) {
      state.isLoading = false;
      state.translations = action.payload;
    },
    // GET Affiliate
    getAffilliateSuccess(state, actions) {
      state.isLoading = false;
      state.affiliateSrc = actions.payload;
    },
    // GET USER FILES
    preparingUploadesFiles(state, actions) {
      state.isLoading = false;
      state.userFiles = actions.payload;
    },
    // GET TEAM USERS 
    getTeamUsersSuccess(state, actions) {
      state.isLoading = false;
      state.teamUsers = actions.payload;
    },
    // GET PLAYER THEMES
    getThemesSuccess(state, action) {
      state.isLoading = false;
      const themesData = action.payload;

      state.playerThemes = themesData.map((theme) => {
        try{
          const data = JSON.parse(theme.data);
          return { ...theme, data };
        }
        catch(err){
          return { ...theme, data:[] };
        }
      });
    },

    // GET USER INTEGRATIONS
    getUserIntegrationsSuccess(state, action) {
      state.isLoading = false;
      const integrations = action.payload;
      if(integrations.length === 0)
        state.openAddIntegration = true
      state.userIntegrations = integrations.map((integration) => {
        const data = JSON.parse(integration.data);
        return { ...integration, data };
      });
    },

    // GET DEFAULT INTEGRATIONS
    getSuppIntegrationsSuccess(state, action) {
      state.isLoading = false;
      state.suppIntegrations = action.payload
    },
    updateUserUsedStorageSuccess(state,action){
      state.isLoading = false;
      state.usedStorage = action.payload
    }
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getTranslations(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/getDefaultTranslations?Token=${token}`);
      dispatch(slice.actions.getTranslationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getUserFilesData(token,type){
  const response = await axios.get(`/user/files?Token=${token}&type=${type}`);
  return response
}

export function getUserFiles(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/files?Token=${token}`);
      dispatch(slice.actions.preparingUploadesFiles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteUserFile(token, file) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/user/files?Token=${token}&file=${encodeURIComponent(file)}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAffiliateDashboard(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://spotlightr.firstpromoter.com/iframe?at=${token}`);
      dispatch(slice.actions.getAffilliateSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getThemes(token) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/playerTheme?Token=${token}`);
      dispatch(slice.actions.getThemesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteTheme(token, themeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/playerTheme?Token=${token}&id=${themeId}`);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function cloneTheme(token, themeData) {
  // eslint-disable-next-line
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = encodeURIComponent(JSON.stringify(themeData));
      const isDefault = data?.default || false;
      const sendData = `Token=${token}&data=${data}&defaultTheme=${isDefault}`;
      await axios.post(`/playerTheme`, sendData);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createTheme(token, themeData) {
  // eslint-disable-next-line
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(themeData);
      const isDefault = data?.default || false;
      const sendData = `Token=${token}&data=${data}&defaultTheme=${isDefault}`;
      const response = await axios.post(`/playerTheme`, sendData);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateTheme(token, themeId, themeData) {
  // eslint-disable-next-line
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const data = JSON.stringify(themeData);
      const sendData = `Token=${token}&id=${themeId}&data=${data}`;
      const response = await axios.put(`/playerTheme`, sendData);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getIntegrations(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/integrations?Token=${user.token}&userID=${user.id}`);
      dispatch(slice.actions.getUserIntegrationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function getUserIntegrationList(user,integration){
  const response = await axios.get(`/user/integrations/lists?Token=${user.token}&userID=${user.id}&integrationID=${integration}`);
  return response
}
export function getSuppIntegrations(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/suppIntegrations?Token=${user.token}`);
      dispatch(slice.actions.getSuppIntegrationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// TEAM USERS
export function getTeamUsers(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/team?Token=${user.token}&userID=${user.id}`);
      dispatch(slice.actions.getTeamUsersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function updateTeamUser(user, teamUser) {
  let data = `Token=${user.token}` +
  `&teamUserID=${teamUser.id}` +
  `&userID=${user.id}` +
  `&admin=${teamUser.admin}` +
  `&active=${teamUser.active}` +
  `&email=${teamUser.email}` +
  `&name=${teamUser.name}` +
  `&permissions=${JSON.stringify(teamUser.permissions)}`
  
  if(teamUser.newPassword) data += `&newPassword=${teamUser.newPassword}`

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/team/update`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function createTeamUser( user, teamUser) {
  const data = `Token=${user.token}` +
    `&userID=${user.id}` +
    `&admin=${teamUser.admin}` +
    `&active=${teamUser.active}` +
    `&email=${encodeURIComponent(teamUser.email)}` +
    `&name=${encodeURIComponent(teamUser.name)}` +
    // `&password=${encodeURIComponent(teamUser.password)}` +
    `&permissions=${JSON.stringify(teamUser.permissions)}`
  
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/team/create`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function deleteTeamUser( user, teamUser) {
  const data = `Token=${user.token}
    &userID=${user.id}
    &teamUserID=${teamUser.id}
  `
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/team/delete`, data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function refreshingStorageInfo(user){
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/user/getStorage?userID=${user.id}`);
      dispatch(slice.actions.updateUserUsedStorageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function getBandwidthUsage(user , dataRange){
  let bandwidthLog=[]
  let url = `/user/consumption?userId=${user.id}`
  if( dataRange ) url += `&from=${dataRange.start}&to=${dataRange.end}`
  const response = await axios.get(url);
  if(response.data){
    bandwidthLog = response.data.map((value)=>{
      value.video = null;
      value.bandwidth = value.amount;
      if(value.awsAmount > value.amount){
        value.bandwidth = value.awsAmount;
      }
      return value
    })
  }
  return bandwidthLog

}

export async function getBandwidthUsageByDate(date,user){
  let bandwidthLog=[]
  const response = await axios.get(`/fix/getBandwidthDate?userID=${user.id}&date=${date}`);
  if(response.data){
    bandwidthLog = response.data.map((value)=>{
      value.bandwidth = value.amount === undefined ? 0:value.amount ;
      value.video = btoa(value.videoID);
      return value
    })
  }
  return bandwidthLog

}
export async function getTranscribeUsage(user){
  const response = await axios.get(`/user/transcribe?userID=${user.id}`);
  return response
}
// export async function getTranscribeUsageByDate(date,user){
//   let transcribeMinutesLog=[]
//   const response = await axios.get(`/user/transcribe?userID=${user.id}&date=${date}`);
//   if(response.data){
//     transcribeMinutesLog = response.data
//   }
//   return transcribeMinutesLog

// }