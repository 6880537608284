import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar, IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

// VERSION
import { SPOTLIGHTR_VERSION } from 'src/config';
import { PATH_AUTH } from '../../../routes/paths';

// hooks
import useAuth from '../../../hooks/useAuth';

import createAvatar from '../../../utils/createAvatar';
// locales
import useLocales from '../../../hooks/useLocales';


// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { user, logout } = useAuth();
  const licenseRef = useRef(null)
  const { translate } = useLocales();
  const navigate = useNavigate();

  const [width, setWidth] = useState(null);

  useEffect(() => {
    setWidth(licenseRef?.current?.offsetWidth);;
  }, [licenseRef, isCollapse]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      // enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        {user && user.firstName ? (
          <Avatar {...createAvatar(user.teamName || user.firstName)} />
        ) : (
          // <Avatar src={`https://www.gravatar.com/avatar/${user.md5}`} alt="user.firstName" />
          <Avatar src="https://minimal-assets-api.vercel.app/assets/images/avatars/avatar_5.jpg" alt="Rayan Moran" />
        )}

        {  !isCollapse &&
          <Box
            sx={{
              maxWidth: "250px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              flexGrow: 1,
              ml: 2,
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.shorter,
                }),
              ...(isCollapse && {
                ml: 0,
                width: 0,
              }),
            }}
          >
            <Box sx={{   display: 'flex', justifyContent: 'space-between'}}>
            <Tooltip title={user && (user.teamName || user.firstName)}>
              <Typography variant="subtitle2" sx={{ textOverflow: 'ellipsis', width: width || 'unset', overflow: 'hidden'}}>
                  {user && (user.teamName || user.firstName)}
              </Typography>
              </Tooltip>
              <IconButton  aria-label="logout" component="span" sx={{p:0}} onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Box>
            <Box ref={licenseRef} sx={{ display: 'flex', justifyContent: 'space-between', maxWidth: '100%' }}>
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                <small>
                  <strong>{user && user.license}</strong> {translate('userProp.license')}
                </small>
              </Typography>
              <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                <small>{SPOTLIGHTR_VERSION}</small>
              </Typography>
            </Box>
          </Box>
        }
      </RootStyle>
    </Link>
  );
}
