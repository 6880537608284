import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export const displayFloat = (input) => {
  const number = Number.isNaN(input) ? 0 : input;

  return parseFloat(number).toFixed(2);
};

export const megaNumber = (number, fractionSize) => {
  if (number === null) return null;
  if (number === 0) return 0;
  if (!fractionSize || fractionSize < 0) fractionSize = 1;

  let abs = Math.abs(number);
  let unit = '';
  const rounder = 10 ** fractionSize;
  const isNegative = number < 0;

  const powers = [
    { key: 'Q', value: 10 ** 15 },
    { key: 'T', value: 10 ** 12 },
    { key: 'B', value: 10 ** 9 },
    { key: 'M', value: 10 ** 6 },
    { key: 'K', value: 1000 },
  ];

  for (let i = 0; i < powers.length; i += 1) {
    let reduced = abs / powers[i].value;

    reduced = Math.round(reduced * rounder) / rounder;

    if (reduced >= 1) {
      abs = reduced;
      unit = powers[i].key;
      break;
    }
  }

  return (isNegative ? '-' : '') + abs + unit;
};
