import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isCommentsLoading: false,
  error: null,
  comments: [],
};

const slice = createSlice({
    name: 'comments',
    initialState,
    reducers: {
      // START LOADING
      startLoading(state) {
        state.isLoading = true;
      },
      startCommentsLoading(state) {
        state.isCommentsLoading = true ;
      },
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.isCommentsLoading =  false;
        state.error = action.payload;
      },
  
      // GET REPORTS
      getCommentReportSuccess(state, action) {
        state.comments = action.payload;
        state.isLoading = false;
        state.isCommentsLoading = false
      },
    },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------
export function getComments(data) {
  return async () => {
    dispatch(slice.actions.startCommentsLoading(true));    
    try {
        const response = await axios.post(`/video/getComments`, data);
        dispatch(slice.actions.getCommentReportSuccess(response.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
  };
}

export async function  updateComment(data){
  const response = await axios.get(`/video/updateComment?id=${data.id}&comment=${data.comment}&response=${data.response}&customViewerID=${data.customViewerID}`)
  return response
}
export async function  deleteComment(id){
  const response = await axios.get(`/video/deleteComment?id=${id}`)
  return response
}