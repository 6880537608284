import { createSlice } from '@reduxjs/toolkit';

// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  quizzes: [],
  currentQuiz: null,
  createdQuizId: null,
  quizResponses: [],
};

const slice = createSlice({
  name: 'quizzes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CREATED ID
    getCreatedQuizId(state, action) {
      state.isLoading = false;
      state.createdQizId = action.payload;
      // console.log(state.createdPlaylistId, 'PUT-PLAYLIST-ID-IN-STATE') 
    },

    // GET QUIZ
    getQuizzesSuccess(state, action) {
      state.isLoading = false;
      state.quizzes = action.payload;
    },

    // GET CURRENT_QUIZ
    getCurrentQuizSuccess(state, action) {
      state.isLoading = false;
      // let quiz = decodeURIComponent(JSON.stringify(action.payload));
      // quiz = JSON.parse(quiz);
      // const quizData = JSON.parse(quiz.data)
      const quiz = action.payload;
      const quizData = JSON.parse(quiz.data);

      quizData.total = 0
      if(!quizData.markerPoints){
        quizData.markerPoints={}
        quizData.markerPoints.markers = []
      } else {
        quizData.markerPoints.markers.forEach(marker =>  {
          if(marker.type !== "identify") {
            quizData.total += marker.points? Number(marker.points): 1;
          }
        })
      }


      if(!quizData.hint) quizData.hint= "show"
      if(!quizData.passingGrade) quizData.passingGrade= 100
      
      state.currentQuiz = { ...quiz, data: quizData };
    },

    // GET CURRENT_QUIZ RESPONSES
    getCurrentQuizResponses(state, action) {
      const quizResponses = action.payload.filter(quizResponse => {
        if(quizResponse.data && quizResponse.data !== "") {
          quizResponse.data = JSON.parse(quizResponse.data);
          quizResponse.score = 0;
          quizResponse.data.forEach((value)=>{
            if(value.status) quizResponse.score += value.points? value.points: 1;
          });
          return quizResponse
        }
        return false;
      })

      state.quizResponses = quizResponses     
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getQuizzes(token,groups = '') {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/quiz?Token=${token}&videoGroup=${groups}`);
      dispatch(slice.actions.getQuizzesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuiz(token, quizId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/quiz?id=${quizId}&Token=${token}`);
      dispatch(slice.actions.getCurrentQuizSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getQuizViews(token, quizId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/quiz/views?quizID=${quizId}&Token=${token}`);     
      dispatch(slice.actions.getCurrentQuizResponses(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getQuizViewsByViewerId(token, customViewerID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/quiz/views?&Token=${token}&email=${customViewerID}`);     
      dispatch(slice.actions.getCurrentQuizResponses(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/* eslint-disable consistent-return */
export function createQuiz(token, name, returnValue,groupId = null) {
  const playlistData = {
    'position': 'right',
    'skin': 'default',
    'allowSkipping': true
  };
  let data = `Token=${token}&name=${encodeURIComponent(name)}&data=${JSON.stringify(playlistData).replace( /&/g, "%26")}`;
  if(groupId){
    data = `${data}&videoGroup=${groupId}`
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/quiz`, data);
      dispatch(slice.actions.getCreatedQuizId(response.data));
      if( returnValue ) return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
} // eslint-disable-line react-hooks/exhaustive-deps
export function updateQuiz(token, quiz,groups=null) {
  // console.log(quiz, 'QUIZ')
  // let data = `Token=${token}&id=${quiz.id}&name=${encodeURIComponent(quiz.name)}&data=${encodeURIComponent(JSON.stringify(quiz.data).replace( /&/g, "%26"))}`;
  // if(groups){
  //   data = `${data}&videoGroups=${groups}`
  // }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await updateQuizzeEndPoint(token,quiz,groups)
      // console.log(response)
      // dispatch(slice.actions.getQuizUpdatedSuccess(response.data));
      dispatch(slice.actions.endLoading());
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return false;
    }
  }
} // eslint-disable-line react-hooks/exhaustive-deps
export async function deleteQuiz(user,selectedQuiz){
  const data = `token=${user.token}&flag=quiz&id=${selectedQuiz}`;
  const response =  await axios.post('/deleteMachine',data);
  return response
}
export async function cloneQuizzes(user,id) {
  const response =  await axios.post(`/clone?Token=${user.token}&id=${id}&table=quiz`);
  return response
};
export async function updateQuizzeEndPoint(token, quiz,groups=null) {
  let data = `Token=${token}&id=${quiz.id}&name=${encodeURIComponent(quiz.name)}&data=${encodeURIComponent(JSON.stringify(quiz.data))}&tags=${typeof quiz.tags !== "string" ? JSON.stringify(quiz.tags) : quiz.tags}`;
  if(groups){
    data = `${data}&videoGroup=${groups}`
  }
  const response = await axios.put(`/quiz`, data);
  return response
}
