
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types';

// @Mui
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import { SlideInRight } from '../layouts/transitions'

LastInvoiceDialog.propTypes = {
    lastInvoice: PropTypes.any
}

export default function LastInvoiceDialog({lastInvoice}) {
    const [open, setOpen] = useState(false)
    useEffect(() => {
      if(lastInvoice) setOpen(true)    
    }, [lastInvoice])
    const openInvoice = () => {
        window.open(lastInvoice, 'new_tab')
        setOpen(false)
    }
    return (<Dialog TransitionComponent={SlideInRight} fullWidth maxWidth="sm" open={open} onClose={()=>setOpen(false)}>
    <DialogContent >         
        <Stack gap={2} alignItems="baseline">
            <Alert severity='error'>
                <Typography>
                    There is a recent failed payment on your latest invoice, please consider settling it to avoid your account expiring
                </Typography>
            </Alert>
            <Button variant='outlined' onClick={openInvoice}>Open Invoice</Button>
        </Stack>
    </DialogContent>  
    </Dialog>)
}
  
