import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import { defaultGigabyteToByteForApplet } from 'src/utils/common';
import BlockContent from './BlockContent';
import RejectionFiles from './RejectionFiles';
import MultiFilePreview from './MultiFilePreview';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme,fileStatus,files }) => ({
  outline: 'none',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  display:(fileStatus && (fileStatus?.toLowerCase() === "processing" || fileStatus?.toLowerCase() === "completed") && files.length !== 0 )?"none":"initial"
}));

// ----------------------------------------------------------------------

UploadMultiFile.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  progress: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  helperText: PropTypes.node,
  sx: PropTypes.object,
  fileName: PropTypes.array,
  handleOnChangeFileName: PropTypes.func,
  handleDisableAccelerateAndRetry: PropTypes.func,
  fileStatus:PropTypes.bool,
  isApplet:PropTypes.bool
};

export default function UploadMultiFile({
  error,
  showPreview = false,
  files,
  onRemove,
  onRemoveAll,
  progress,
  helperText,
  sx,
  fileName,
  handleOnChangeFileName,
  handleDisableAccelerateAndRetry,
  fileStatus,
  isApplet,
  ...other
}) {

  // eslint-disable-next-line consistent-return
  const getMaxSize = () => {
    if(isApplet) return defaultGigabyteToByteForApplet
  }
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: !isApplet,
    maxSize: getMaxSize(),
    ...other,
  });
  const uploadLimit = 20;
  
  return (
    <Box sx={{ width: '100%', ...sx }}>
      {<DropZoneStyle fileStatus={fileStatus} files={files}
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
            
          }),
        }}
      >
        <input {...getInputProps()}  disabled={files.length >= uploadLimit || (isApplet && files.length > 0)}/>

        <BlockContent />
      </DropZoneStyle>}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      <MultiFilePreview
        files={files}
        showPreview={showPreview}
        onRemove={onRemove}
        onRemoveAll={onRemoveAll}
        progress={progress}
        fileName={fileName}
        handleOnChangeFileName={handleOnChangeFileName}
        handleDisableAccelerateAndRetry={handleDisableAccelerateAndRetry}
        fileStatus={fileStatus}
        isApplet={isApplet}
      />

      {helperText && helperText}
    </Box>
  );
}
