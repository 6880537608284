// routes
import { PATH_DASHBOARD } from './routes/paths';

// base path
export const BASE_PATH = process.env.REACT_APP_BASE_PATH || '5';

// Intercom

export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

// VERSION
// ----------------------------------------------------------------------

export const SPOTLIGHTR_VERSION = process.env.REACT_APP_SPOTLIGHTR_VERSION || '';

// STRIPE
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';

// HUBSPOT
export const HUBSPOT_REDIRECT = process.env.REACT_APP_HUBSPOT_REDIRECT || 'not found';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const PUBLISH_DRAWER = {
  BASE_WIDTH: 500,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: false,
};

export const demoVideoId='MTIwNTg0Mg'

export const tinyMceEditorApiKey='uz2ves5iv4r7ms7y1n6hjmc7kx8qhozjng1ji8vqraysu595'

export const REACT_APP_GA_ID = process.env.REACT_APP_GA_ID || '';

export const REACT_APP_CDN77_DOMAIN = process.env.REACT_APP_CDN77_DOMAIN || '';

export const REACT_APP_GOOGLE_CLIENT_ID= process.env.REACT_APP_GOOGLE_CLIENT_ID || '';

export const REACT_APP_MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY || '';

export const REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '';