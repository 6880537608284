import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';

// components
import Iconify from './Iconify';

// ----------------------------------------------------------------------
const shouldForwardPropList = ['horizontal','type']

const RootStyle = styled(Card, {
  shouldForwardProp: (prop) => shouldForwardPropList.indexOf(prop) < 0,
})(({ theme, horizontal, type }) => (
  horizontal ? {
    display: 'flex',
    alignItems:'center',
    gap: 8,
    padding:8,
    paddingRight:16
  } : {
    boxShadow: 'none',
    textAlign: type !== 'monthWiseAverage' ? 'center' : 'left',
    padding: theme.spacing(5, 0)
  })
);

const IconWrapperStyle = styled('div', {
  shouldForwardProp: (prop) => shouldForwardPropList.indexOf(prop) < 0,
})(({ theme, horizontal }) => ({
  margin: horizontal ? 0 : 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(horizontal ? 6 : 5),
  height: theme.spacing(horizontal ? 6 : 5),
  justifyContent: 'center',
  marginBottom: horizontal ? 0 : theme.spacing(2),
}));

// ----------------------------------------------------------------------

AnalyticsWidgetSummary.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error']),
  icon: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.string,
  horizontal: PropTypes.bool,
  type: PropTypes.string
};

export default function AnalyticsWidgetSummary({ title, total, icon, color = 'primary', horizontal = false , type}) {
  return (
    <RootStyle
      horizontal={horizontal}
      type={type}
      sx={{
        color: (theme) => theme.palette[color].darker,
        bgcolor: (theme) => theme.palette[color].lighter,
        padding: type === 'monthWiseAverage' ? '15px' : ''
      }}
    >
      {type !== 'monthWiseAverage' && <IconWrapperStyle
         horizontal={horizontal}
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,          
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle>}

      {horizontal && <Stack>
        <Typography variant="subtitle1">{total}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>     
      </Stack>}

      {!horizontal && <>
        <Typography variant={type === 'monthWiseAverage' ? "h6" : "h3"}>{total}</Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
      </>}
    </RootStyle>
  );
}
