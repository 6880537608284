
import { useEffect } from "react";
import { useNavigate } from "react-router";

//  @mui
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"

// @icons
import CloseIcon from '@mui/icons-material/Close';

// @hooks
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import useLocalStorage from "src/hooks/useLocalStorage";

// @utils
import {trackEventActionsFunction } from '../../../../utils/common';
import { getTimeFromNow } from "../../../../utils/formatTime";
import { calculateRemainingTrialDays } from "../../../../layouts/dashboard/navbar/NavbarTrialExpire";





export default function TrialExpireNotification() {
    const { user } = useAuth()
    const navigate = useNavigate()  
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [trialNotification, setTrialNotification] = useLocalStorage('trialNotification')

    const subsribeButtonClick = (id) => {
        navigate(`/account`, {state:{trialExpire:true}, replace:false});        
        trackEventActionsFunction('Billing', 'User Action', 'Trial Notification')
        closeSnackbar(id)
    }

    const action = snackbarId => (
      <>
        <Button variant="outlined" size="medium" onClick={() =>subsribeButtonClick()}>
          Subscribe
        </Button>        
        <IconButton
          sx={{ml:1}}
          onClick={() => {closeSnackbar(snackbarId)}}
        >                    
          <CloseIcon />        
        </IconButton> 
      </>
    );
    
    useEffect(() => {
      if(
        user && calculateRemainingTrialDays(user.trialExpire).days > -1 && 
        calculateRemainingTrialDays(user.created).days > 1 &&
        (!trialNotification || getTimeFromNow(new Date(trialNotification),'h') > 48 )
      ) {
        setTrialNotification(new Date())
        enqueueSnackbar(`Trial expires in ${calculateRemainingTrialDays(user.trialExpire).days} days. Subscribe and get 2 months free with annual subscriptions`, 
          {
            variant:"info",
            autoHideDuration: 15000,
            anchorOrigin: {horizontal: "center", vertical: "top"},
            action
          }
        )    
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
  
  return null
}