import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import {  useEffect, useState  } from 'react';
import { useIntercom } from 'react-use-intercom';
// @mui
import useAuth from 'src/hooks/useAuth';
import { Badge, Box, Dialog, DialogContent, Link, ListItemText } from '@mui/material';
//
// import UserConsumptionDialoge from 'src/sections/@dashboard/accountBilling/shared/UserConsumptionDialoge';

import Iconify from '../../Iconify';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from './style';
import { isExternalLink } from '..';
import IdeasBoard from '../../../pages/IdeasBoard';
import { SlideInRight } from '../../../layouts/transitions';
import { getTimeFromNow } from '../../../utils/formatTime';


// ----------------------------------------------------------------------


NavItemRoot.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  isCollapse: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    icon: PropTypes.any,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
  }),
};

export function NavItemRoot({ item, isCollapse, open = false, active, onOpen }) {
  const { title, path, icon, info, children } = item;
  const [dialogOpen, setDialogOpen] = useState(false)
  const {user,badgeViewers} = useAuth()
  const [badgeWiggle, setBadgeWiggle] = useState(true) 
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      setBadgeWiggle(false);
    }, 3600);
    return () => clearTimeout(timeout);
  }, []);

  const showWiggle = () => 
    badgeWiggle 
    && badgeViewers > 0 
    && (!user.globalSettings?.lastRingDate || getTimeFromNow(new Date(user.globalSettings.lastRingDate), 'days')  > 7)

  const renderContent = ( 
    <>
      {icon && <>
        {title === "Student Reports" && badgeViewers > 0 ?         
          <ListItemIconStyle>
            <Badge 
              badgeContent={badgeViewers}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              // color="error"  
              sx={{...{
                '& .MuiBadge-badge': {
                  minWidth:'.9rem',
                  height:'.9rem', 
                  borderRadius:'.25rem', 
                  fontSize: '.6rem',
                  backgroundColor: showWiggle() ? 'red' : '#808080',
                  color: '#fff',
                  margin: showWiggle() ? '0px -9px -7px 0px' : '',
                  animation: showWiggle() ? 'wiggle infinite alternate' : 'none',
                  animationDuration: showWiggle() ? '.5s' : 0,
                  transformOrigin: 'center'
                },
                '& svg': {width:'1em',height:'1em'},
                '@keyframes wiggle': {
                  '0%': { transform: 'rotate(-10deg);'},
                  '20%': { transform: 'rotate(10deg);'},
                  '40%': { transform: 'rotate(-10deg);'},
                  '60%': { transform: 'rotate(10deg);'},
                  '80%': { transform: 'rotate(0deg);'},
                  '100%': { transform: 'rotate(0deg);'}
                }
              }}}>{icon}
            </Badge>
          </ListItemIconStyle>         
        :
        <ListItemIconStyle>{icon}</ListItemIconStyle>}
      </>}
      
      <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse} />
      {!isCollapse && (
        <>
          {info && info}
          {children && <ArrowIcon open={open} />}
        </>
      )}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent} 
      </ListItemStyle>
    );
  }

  return isExternalLink(path) ? (
    <ListItemStyle component={Link} href={path} target="_blank" rel="noopener">
      {renderContent}
    </ListItemStyle>
  ) : (
    <>
      {path === '/ideas' ? <>
        <ListItemStyle onClick={()=>setDialogOpen(true)}>
          {renderContent}
        </ListItemStyle> 
       
      <Dialog fullWidth maxWidth="xl" open={dialogOpen} onClose={()=>setDialogOpen(false)}>
         <DialogContent TransitionComponent={SlideInRight} sx={{overflow: 'hidden', p:0, py:3}}>
           <IdeasBoard />
         </DialogContent>          
        </Dialog>
      </>
      : <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
        {renderContent}
      </ListItemStyle>}
    </>
  );
}

// ----------------------------------------------------------------------

NavItemSub.propTypes = {
  active: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  item: PropTypes.shape({
    children: PropTypes.array,
    info: PropTypes.any,
    path: PropTypes.string,
    title: PropTypes.string,
    isTokenRequired:PropTypes.any,
    intercom:PropTypes.bool,
    // openBandwidthPopup:PropTypes.bool,
  }),
};

export function NavItemSub({ item, open = false, active = false, onOpen }) {
  const [openIntercom, setOpenIntercom] = useState(false)
  const { title, info, path,children,isTokenRequired, intercom } = item;
  const { user } = useAuth();
  // const [openDialoge, setOpenDialoge] = useState(false)
   
  const { show, hide } = useIntercom();

  const toggleSupport = () => {
    if(!openIntercom) {      
      show()
    } else {
      hide()
    }
    setOpenIntercom(!openIntercom)
  }

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={title} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }
  if(intercom) return (<ListItemStyle onClick={toggleSupport} subItem>{renderContent}</ListItemStyle>)

  // if(openDialoge && openBandwidthPopup)
  //   return(<ListItemStyle subItem>{renderContent}<UserConsumptionDialoge usageType='bandwidth' user={user} handleClose={()=>{setOpenDialoge(!openDialoge)}}/></ListItemStyle>)  

  //   if(!openDialoge && openBandwidthPopup)
  //   return(<ListItemStyle onClick={()=>{setOpenDialoge(!openDialoge)}} subItem>{renderContent}</ListItemStyle>)

    return path && isExternalLink(path) ? (
    <ListItemStyle component={Link} href={isTokenRequired && user.token ? path.replace("user.token.here",user.token):path} target="_blank" rel="noopener" subItem>
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
ArrowLeftIcon.propTypes = {
  handleClick: PropTypes.func,
  pointer : PropTypes.any
};
export function ArrowLeftIcon({handleClick, pointer}){
  return (
    <Iconify
      color={'primary'}
      icon={'eva:arrow-circle-left-outline'}
      sx={{ width: 24, height: 24, ml: 0, mb:-.66, color: '#00897b', cursor: pointer ? 'pointer' : 'unset' }}
      onClick={handleClick}
    />
  );
}
