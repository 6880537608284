import { createSlice } from '@reduxjs/toolkit';
import { jsonToUrlParams } from 'src/utils/common';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isReportsLoading: null,
  error: null,
  reports: null,
  savedReports:null
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startEndReportLoading(state,action) {
      state.isReportsLoading = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isReportsLoading =  false;
      state.error = action.payload;
    },

    // GET REPORTS
    getReportsSuccess(state, action) {
      state.isLoading = false;
      state.reports = action.payload;
      state.isReportsLoading =  false;
    },
    getUserReportData(state,action){
      state.isLoading = false;
      state.savedReports = action.payload
    }
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getReports(user, params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startEndReportLoading(true));
    try {
      const response = await getReportsMethod(user, params);
      dispatch(slice.actions.getReportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function getReportsMethod(user, params){
  const para = jsonToUrlParams(params)
  const url = `reports/run?userID=${user.id}&${para}`
  const response = await axios.get(url);
  return response
}
export const getUserReportApi =(userId)=>{
  dispatch(slice.actions.startLoading());
  return async () => {
    const userReportUrl = `/reports/get?userID=${userId}`;
    try{
      const response = await axios.get(userReportUrl)
      dispatch(slice.actions.getUserReportData(response.data));
      return true
    }
    catch(error){
      dispatch(slice.actions.hasError(error));
      return false
    }
  }
}
export async  function automateReport(user, params){
  const para = jsonToUrlParams(params)
  const url = `reports/save?userID=${user.id}&${para}`
  const response = await axios.get(url);    
  return response  
}
