import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { LicenseInfo } from '@mui/x-license-pro';

// GA
// import ReactGA from 'react-ga';

import ReactGA from "react-ga4";


// Cookie
import Cookies from 'js-cookie';

// hooks
import useAuth from './hooks/useAuth';

// routes
import Router from './routes';

// theme
import ThemeProvider from './theme';

// utils
import axios from './utils/axios';

// components
// import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import LastInvoiceDialog from './components/LastInvoiceDialog';

import { REACT_APP_GA_ID, REACT_APP_MUI_LICENSE_KEY } from './config';
import { trackEventActionsFunction } from './utils/common';
import useResponsive from './hooks/useResponsive';

// ----------------------------------------------------------------------

// Init Google Analytics
// ReactGA.initialize('UA-180452448-6');

ReactGA.initialize(REACT_APP_GA_ID);
LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE_KEY);

export default function App() {
  const location = useLocation();
  const { boot, trackEvent, shutdown, update } = useIntercom();  
  const isMobile = useResponsive('down', 'sm');
  const { user, isAuthenticated, userSurvey,setUserSurvey, isApplet } = useAuth();    
  
  useEffect(() => {
    if (user && isAuthenticated) {
      // trackEvent(`Page View-${location.pathname}`);
      // ReactGA.pageview(location.pathname);
      ReactGA.send({ hitType: "pageview", page: location.pathname });
      if(user.isAdmin)
        localStorage.setItem('isAdmin',user.isAdmin)
    }
  }, [location, user, isAuthenticated, trackEvent]);

  useEffect(() => {
    // Intercom Shutdown for new session
    shutdown()

    if(user && isAuthenticated){              
      let adminLoginLink = `https://projects.spotlightr.com/adminuser/${user.token}`
      if(user.isAdmin){
        adminLoginLink = `${adminLoginLink}&from=amember`
      }
      // Intercom Boot
      if(user.subuser < 1 && userSurvey && Object.keys(userSurvey).length > 0 && !isApplet){
        setTimeout(()=>{
          boot({
            app_id: 'qo0qfzxq',
            email: user.username,
            name: user.firstName,
            id: user.intercomID,           
            user_id: user.id,
            customAttributes: {
              demo: user.demo,
              amember_link: `https://main.spotlightr.com/members/admin-users/index?_u_filter=${user.username}`,
              admin_login: adminLoginLink,
              plan_name: user.license,
              primary_usecase: userSurvey.usecase,
              primary_need: userSurvey.primaryNeed,
              previous_solution: userSurvey.previousSolution,
              current_situation: userSurvey.currentSituation,
              user_hash:user.intercomHashEmail
            }
          })
          setUserSurvey(null)
        },5000)
       
        
        return
      }
      if(!(user.restricted && !user.teamUser.permissions.support) && !isApplet){
        boot({
          app_id: 'qo0qfzxq',
          email: user.username,
          name: user.firstName,
          id: user.intercomID,         
          user_id: user.id,
          demo: user.demo,
          customAttributes: {
            amember_link: `https://main.spotlightr.com/members/admin-users/index?_u_filter=${user.username}`,
            admin_login: adminLoginLink,
            plan_name: user.license,
            user_hash:user.intercomHashEmail
          }
        });
      } 
      if(isApplet){
        boot({
          app_id: 'qo0qfzxq'
        });
      }

      // first promoter
      const fpromTrack = Cookies.get('_fprom_track');
      if (fpromTrack && user.trialExpire !== '0000-00-00') {
        
        Cookies.remove('_fprom_track', { path: '/', domain: '.spotlightr.com' });
        signupAffiliateLead(fpromTrack, user);
      }
      if(user.isAdmin)
        localStorage.setItem('isAdmin',user.isAdmin)
    }
    else{
      boot({
        app_id: 'qo0qfzxq'
      })
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated, boot,shutdown, userSurvey]);

  update({alignment:isMobile ? 'left' :'right'})
  const signupAffiliateLead = (fpTrackingId, user) => {
    try{
      trackEventActionsFunction('Registration', 'Affiliate Signup', 'Track Signup')
      const data = `email=${user.username}&tid=${fpTrackingId}&uid=${user.processorID}`;
      axios.post('https://firstpromoter.com/api/v1/track/signup', data, {
        headers: {
          'x-api-key': '78e4c89e2be059fbdde22efe0168a9b3'
        }});
        trackEventActionsFunction('Registration', 'Affiliate Signup', 'API Success')
    }
    catch(err){
      trackEventActionsFunction('Registration', 'Affiliate Signup', 'API Fail')
    }
    
  };

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <NotistackProvider>
            <MotionLazyContainer>
              <LastInvoiceDialog lastInvoice={user?.lastInvoice}/>
              <ProgressBarStyle />
              <ScrollToTop />
              <Router />
            </MotionLazyContainer>
          </NotistackProvider>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
