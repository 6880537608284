import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import playlistsReducer from './slices/playlists';
import quizzessReducer from './slices/quizzes';
import galleriesReducer from './slices/galleries';
import videosReducer from './slices/videos';
import paymentsReducer from './slices/payments';
import audiencesReducer from './slices/audiences';
import reportsReducer from './slices/reports';
import commentsReducer from './slices/comments';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  settings: settingsReducer,
  playlists: playlistsReducer,
  quizzes: quizzessReducer,
  galleries: galleriesReducer,
  videos: videosReducer,
  payments: paymentsReducer,
  audiences: audiencesReducer,
  reports: reportsReducer,
  comments: commentsReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
