import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Hotkeys from 'react-hot-keys';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Drawer, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import CustomSearchDialog from 'src/components/shared/CustomSearchDialog';
import { displayBandwidthPercentage } from 'src/utils/common';

// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
// import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import navSupportConfig from './NavSupportConfig';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import useAuth from '../../../hooks/useAuth';
import NavbarTrialExpire from './NavbarTrialExpire';
import NavbarTrialExpireBandwidth from './NavbarTrialExpireBandwidth';
import TrialExpireNotification from '../../../sections/@dashboard/accountBilling/shared/TrialExpireNotification';




// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
    '[data-simplebar="init"]': {
      overflowX: 'hidden'
    }
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};



export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  // const theme = useTheme();
  const [open, setOpen] = useState(false);

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const { user } = useAuth();

  const createRestrictedNav = ( nav ) => {
    if(user.restricted) {
        if(nav?.subheader === 'Analytics' && !user.teamUser.permissions.analytics) return false
    }   
    return nav
  }
  const restrictedNavConfig = navConfig.filter(createRestrictedNav)

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onToggleCollapse,
    onHoverEnter,
    onHoverLeave,
    // onToggleCollapseSearchBar,
  } = useCollapseDrawer();

  useEffect(()=>{
    setOpen(false)
  },[])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const onKeyDown = (keyName) => {
    if(keyName === 'alt+s' && !open){
      setOpen(!open)
    }    
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          // ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Logo />
          {(!isCollapse || !isDesktop) && (
            <Stack direction="row" alignItems="center">
              {/* <IconButton onClick={onToggleCollapseSearchBar}>
                <SearchIcon />
              </IconButton> */}
               <IconButton onClick={()=>{handleOpenCloseSearch(!open)}}>
                <SearchIcon />
              </IconButton>
              {isDesktop && <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
            </Stack>
          )}
        </Stack>
      </Stack>
      
      {/* https://spotlightr.atlassian.net/jira/software/projects/S5/boards/10?selectedIssue=S5-1069 */}
      {/* this statement works best  isCollapse && !(isOpenSidebar && !isDesktop */}
      {/* <NavSectionVertical navConfig={restrictedNavConfig} isCollapse={isCollapse} /> */}
      <NavSectionVertical navConfig={restrictedNavConfig} isCollapse={isCollapse && !(isOpenSidebar && !isDesktop)} />

      <Box sx={{ flexGrow: 1 }} />

      <NavSectionVertical navConfig={navSupportConfig} isCollapse={isCollapse && !(isOpenSidebar && !isDesktop)} />
      {/* <NavSectionVertical navConfig={navSupportConfig} isCollapse={isCollapse} /> */}
      {user && user.trialExpire !== '0000-00-00' && <NavbarTrialExpire isCollapse={isCollapse && !(isOpenSidebar && !isDesktop) } user={user} />}
      {user && user.trialExpire === '0000-00-00' && user.bandwidthUsed > 0 && user.bandwidthAvailable > 0 && parseFloat((user.bandwidthUsed/1024) / (user.bandwidthAvailable / 1) * 100).toFixed(2) > displayBandwidthPercentage && <NavbarTrialExpireBandwidth isCollapse={isCollapse && !(isOpenSidebar && !isDesktop) } user={user}/>}

      
      <NavbarAccount isCollapse={isCollapse && !(isOpenSidebar && !isDesktop)} />
      {/* <NavbarAccount isCollapse={isCollapse} /> */}
    </Scrollbar>
  );

  const handleOpenCloseSearch = (open) => {
    setOpen(open)
  }  

  return (
    <>
    <RootStyle id="sidebarid"
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: '#f4f4f4 !important',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
    <Hotkeys keyName="alt+s" onKeyDown={onKeyDown}>
      <CustomSearchDialog open={open} handleClose={()=>{handleOpenCloseSearch(!open)}} />
    </Hotkeys>
    {user.trialExpire !== '0000-00-00' && <TrialExpireNotification/>}
    </>
  );
}
