import PropTypes from 'prop-types';
import { createContext } from 'react';
import useAuth from '../hooks/useAuth';


import { uploadAssetsRequest } from '../redux/slices/videos';

// ----------------------------------------------------------------------

const initialState = {
  init: false,
};

const TinymceContext = createContext(initialState);

// ----------------------------------------------------------------------

TinymceProvider.propTypes = {
  children: PropTypes.node,
};

function TinymceProvider({ children }) {
    const { user } = useAuth()
    const uploadRequest = async (file, onUploadProgress) => {
            const data={}
            data.token = user.token
            data.id = user.id
            data.file = file                
            return uploadAssetsRequest(data,  onUploadProgress )
        };
    const handeUploadAssets = async (file )=>{
            const res = await uploadRequest(file, () => {});
            if(res.data) return res.data
            return ''        
    } 
    const  init= {
        height: 300,
        plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
        ],  
        image_title: true,
        /* enable automatic uploads of images represented by blob or data URIs */
        // automatic_uploads: true,
        /*
        URL of our upload handler (for more details check: https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_url)
        images_upload_url: 'postAcceptor.php',
        here we add custom filepicker only to Image dialog
        */
        file_picker_types: 'image',
        /* and here's our custom image picker */
        // eslint-disable-next-line no-unused-vars
        file_picker_callback  (callback, value, meta) {                                                                
                const input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = async (e) => {
                        const title = e.target.files[0].name                    
                        const imageUrl = await handeUploadAssets(e.target.files[0])
                        callback(imageUrl, { title });
                }

                input.click();
        },
        menubar: true,
        toolbar: 'image | undo redo | formatselect | ' +
        'bold italic backcolor | alignleft aligncenter ' +
        'alignright alignjustify | bullist numlist outdent indent | ' +
        'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        placeholder: "Enter Content"
    }


  return (
    <TinymceContext.Provider
      value={{
        init
      }}
    >
      {children}
    </TinymceContext.Provider>
  );
}

export { TinymceProvider, TinymceContext };
